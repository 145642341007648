import moment from 'moment'

export default () => {
  const updateCalendar = (calendar, date) => {
    const daysMax = date.daysInMonth()
    let currDay = 1
  
    for (let i = 0; i < calendar.children.length; i++) {
      const tableRow = calendar.children[i]
  
      for (let j = 0; j < tableRow.children.length; j++) {
        const tableData = tableRow.children[j]
        tableData.removeEventListener('click', clickDay)
        tableData.textContent = ''
        tableData.removeAttribute('data-date')
        tableData.classList.remove('first', 'last')
  
        if (j === date.clone().date(currDay).day() && currDay <= daysMax) {
          tableData.addEventListener('click', clickDay)
  
          tableData.removeEventListener('click', doubleClickStart)
          tableData.removeEventListener('click', doubleClickEnd)
  
          tableData.textContent = currDay
          tableData.dataset.date = date.date(currDay).format('YYYY-MM-DD')
  
          if (currDay === 1) tableData.classList.add('first')
          if (currDay === daysMax) tableData.classList.add('last')
  
          currDay++
        }
      }
    }
  }
  
  const updateSelected = (calendar, start, end) => {
    let startEl = null, endEl = null
  
    for (let i = 0; i < calendar.children.length; i++) {
      const tableRow = calendar.children[i]
  
      for (let j = 0; j < tableRow.children.length; j++) {
        const tableData = tableRow.children[j]
        const elDate = tableData.dataset.date
  
        tableData.classList.remove('selected', 'middle-selected', 'start', 'end')
  
        if (elDate) {
          if (start && !startEl && moment(elDate, 'YYYY-MM-DD').isSame(start)) {
            tableData.classList.add('selected')
            if (end) tableData.classList.add('start')
  
            tableData.addEventListener('click', doubleClickStart, { once: true })
  
            startEl = tableData
          }
  
          else if (end && !endEl && moment(elDate, 'YYYY-MM-DD').isSame(end)) {
            tableData.classList.add('selected', 'end')
            tableData.addEventListener('click', doubleClickEnd, { once: true })
  
            endEl = tableData
          }
  
          else if (moment(elDate, 'YYYY-MM-DD').isBetween(start, end)) {
            tableData.classList.add('middle-selected')
          }
        }
      }
    }
  }
  
  function clickDay() {
    this.dispatchEvent(new CustomEvent('update-range', { detail: { action: 'add', value: this.dataset.date }, bubbles: true }))
  }
  
  function doubleClickStart() {
    this.dispatchEvent(new CustomEvent('update-range', { detail: { action: 'clear-both' }, bubbles: true }))
  }
  
  function doubleClickEnd() {
    this.dispatchEvent(new CustomEvent('update-range', { detail: { action: 'clear-end' }, bubbles: true }))
  }

  document.querySelectorAll('.vis-date-range-picker').forEach(container => {
    const input = container.querySelectorAll('.hidden-input')

    const calendarContainer = container.querySelector('.calendar-container')
    const background = container.querySelector('.dark-background')

    const calendar = container.querySelectorAll('.calendar tbody')[0]
    const calendar2 = container.querySelectorAll('.calendar tbody')[1]

    const month = container.querySelectorAll('.month')[0]
    const month2 = container.querySelectorAll('.month')[1]

    let date = moment()
    let start = moment(input[0].value, 'YYYY-MM-DD').isValid() ? moment(input[0].value, 'YYYY-MM-DD') : null, end = moment(input[1].value, 'YYYY-MM-DD').isValid() ? moment(input[1].value, 'YYYY-MM-DD') : null
    let open = false

    container.addEventListener('update-container', (e) => {
      const data = e.detail

      switch (data.action) {
        case 'open-close':
          calendarContainer.toggleAttribute('hidden')
          background.toggleAttribute('hidden')

          if (open) container.dispatchEvent(new CustomEvent('update-range', { detail: { action: 'clear-both' } }))

          open = !open
          break

        case 'save':
          if (!start || !end) {
            input[0].value = ''
            input[1].value = ''

            break
          }

          calendarContainer.setAttribute('hidden', '')
          background.setAttribute('hidden', '')

          input[0].value = start.format('YYYY-MM-DD')
          input[1].value = end.format('YYYY-MM-DD')

          open = false
          break
      }
    })

    container.addEventListener('update-month', (e) => {
      const data = e.detail

      switch (data.action) {
        case 'add':
          date.add(data.value, 'month')
          break

        case 'sub':
          date.subtract(data.value, 'month')
          break

        case 'set':
          date = data.value
          break
      }

      month.textContent = date.format('MMMM YYYY')
      updateCalendar(calendar, date)
      updateSelected(calendar, start, end)

      month2.textContent = date.clone().add(1, 'month').format('MMMM YYYY')
      updateCalendar(calendar2, date.clone().add(1, 'month'))
      updateSelected(calendar2, start, end)
    })

    container.addEventListener('update-range', (e) => {
      const data = e.detail

      switch (data.action) {
        case 'add':
          if (!start)
            start = moment(data.value, 'YYYY-MM-DD')

          else if (!end && moment(data.value, 'YYYY-MM-DD').isAfter(start))
            end = moment(data.value, 'YYYY-MM-DD')

          break

        case 'clear-both':
          if (start) {
            calendar.querySelector(`[data-date='${start.format('YYYY-MM-DD')}']`)?.removeEventListener('click', doubleClickStart)
            calendar2.querySelector(`[data-date='${start.format('YYYY-MM-DD')}']`)?.removeEventListener('click', doubleClickStart)
          }

          if (end) {
            calendar.querySelector(`[data-date='${end.format('YYYY-MM-DD')}']`)?.removeEventListener('click', doubleClickEnd)
            calendar2.querySelector(`[data-date='${end.format('YYYY-MM-DD')}']`)?.removeEventListener('click', doubleClickEnd)
          }

          start = end = null

          input[0].value = ''
          input[1].value = ''
          break

        case 'clear-start':
          start = null

          input[0].value = ''
          input[1].value = ''
          break

        case 'clear-end':
          calendar.querySelector(`[data-date='${end.format('YYYY-MM-DD')}']`)?.removeEventListener('click', doubleClickEnd)
          calendar2.querySelector(`[data-date='${end.format('YYYY-MM-DD')}']`)?.removeEventListener('click', doubleClickEnd)
          end = null

          input[0].value = ''
          input[1].value = ''
          break
      }

      updateSelected(calendar, start, end)
      updateSelected(calendar2, start, end)
    })

    background.addEventListener('click', function () {
      this.dispatchEvent(new CustomEvent('update-container', { detail: { action: 'open-close' }, bubbles: true }))
    })

    const button = container.querySelector('.calendar-button')

    button.addEventListener('click', function () {
      this.dispatchEvent(new CustomEvent('update-container', { detail: { action: 'open-close' }, bubbles: true }))
    })

    const clearBtn = container.querySelector('button.clear')
    const saveBtn = container.querySelector('button.save')

    clearBtn.addEventListener('click', function () {
      this.dispatchEvent(new CustomEvent('update-range', { detail: { action: 'clear-both' }, bubbles: true }))
    })

    saveBtn.addEventListener('click', function () {
      this.dispatchEvent(new CustomEvent('update-container', { detail: { action: 'save' }, bubbles: true }))
    })

    const prevMonthEl = container.querySelectorAll('.prev-month')
    const nextMonthEl = container.querySelectorAll('.next-month')

    prevMonthEl.forEach(el => {
      el.addEventListener('click', function () {
        this.dispatchEvent(new CustomEvent('update-month', { detail: { action: 'sub', value: 1 }, bubbles: true }))
      })
    })

    nextMonthEl.forEach(el => {
      el.addEventListener('click', function () {
        this.dispatchEvent(new CustomEvent('update-month', { detail: { action: 'add', value: 1 }, bubbles: true }))
      })
    })

    container.dispatchEvent(new CustomEvent('update-month', { detail: { action: 'set', value: date } }))
  })
}
