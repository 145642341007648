(function (root, factory) { // don't worry too much about this, it's just for module compatibility
  if (typeof define === 'function' && define.amd)
    define([], function () {
      return (root.ShareButtonsVis = factory())
    })

  else if (typeof exports === 'object')
    module.exports = factory()

  else
    root.ShareButtonsVis = factory()

}(typeof self !== 'undefined' ? self : this, function () {
  'use strict'

  function ShareButtonsVis() {
    const copiedBubble = new DOMParser().parseFromString('<span class="absolute cursor-default -top-2 left-1/2 transform -translate-x-1/2 -translate-y-full rounded p-1.5 bg-vis-blue-2 text-white z-50">Copied</span>', 'text/html').body.firstChild
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/

    const smsUrlBase = getMobileOperatingSystem() === 'iOS' ? 'sms:?&body=' : 'sms:?body='

    /**
     * This object holds all available share methods, adding a new one is as simple
     * as adding a new property with it's own funtion.
     */
    const AVAILABLE_SHARE_METHODS = {
      facebook: el => {
        el.onclick = event => {
          const { link, text } = getData(event.target)

          window.open(encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${link}&quote=${text.replace(urlRegex, '').trim()}`), '_blank')
        }
      },

      twitter: el => {
        el.onclick = event => {
          const { text } = getData(event.target)

          window.open(encodeURI(`https://twitter.com/intent/tweet?text=${text}`), '_blank')
        }
      },

      email: el => {
        el.onclick = event => {
          const { title, text } = getData(event.target)

          window.open(encodeURI(`mailto:?subject=${title}&body=${text}`))
        }
      },

      sms: el => {
        el.onclick = event => {
          const { text } = getData(event.target)

          window.open(encodeURI(`${smsUrlBase}${text}`))
        }
      },

      copy: el => {
        if (!navigator.clipboard) {
          el.hidden = true

          if (el.parentElement.nodeName === 'LI') el.parentElement.hidden = true
          return
        }

        el.onclick = event => {
          if (event.target.nodeName === 'SPAN') return

          const { text } = getData(event.target)

          navigator.clipboard.writeText(text)
            .then(() => {

              event.target.appendChild(copiedBubble)

              setTimeout(() => {
                if (event.target.contains(copiedBubble))
                  event.target.removeChild(copiedBubble)
              }, 1000)
            })
        }
      },

      share: el => {
        if (!navigator.canShare) {
          el.hidden = true

          if (el.parentElement.nodeName === 'LI') el.parentElement.hidden = true
          return
        }

        el.onclick = event => {
          const { link, text, title } = getData(event.target)

          navigator.share({
            url: link,
            title,
            text: text.replace(urlRegex, '').trim(),
          })
        }
      },
    }

    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    function getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }

      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }

      return 'unknown'
    }

    function getData(el) {
      const { dataset } = el
      const parentDataset = el.parentElement.nodeName === 'LI' ? el.parentElement.parentElement.dataset : el.parentElement.dataset

      const link = dataset.link || parentDataset.link || window.location.href
      const title = dataset.title || parentDataset.title || 'Voice in Sport'
      const text = dataset.text || parentDataset.text || `Check this out at VOICEINSPORT®!\r\n${link}`

      return {
        link: link.replace(/<\/*\w+>/g, ''),
        title: title.replace(/<\/*\w+>/g, ''),
        text: text.replace(/<\/*\w+>/g, ''),
      }
    }

    /**
     * If you generate some share buttons dynamically, don't forget to run this method.
     */
    this.update = function () {
      // all share buttons must have this attribute
      const shareButtons = document.querySelectorAll('[vis-share-button]')

      shareButtons.forEach(el => {
        const { type } = el.dataset
        const shareMethod = AVAILABLE_SHARE_METHODS[type]

        if (typeof shareMethod === 'function') shareMethod(el)
      })
    }
  }

  return new ShareButtonsVis() // this instance will be attached to the window object
}));