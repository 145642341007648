// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// import "intl-tel-input/src/css/intlTelInput.scss"

//= require local-time

// Needed to access $ from js.erb
var $ = require("jquery");
global.$ = $;
window.jQuery = $;

require("linkifyjs/dist/linkify.min");
require("linkifyjs/dist/linkify-jquery.min");
require("intl-tel-input/build/js/intlTelInput.min");
require("intl-tel-input/build/js/utils")
require("slick-carousel");
require("typed.js")
require("waypoints/lib/noframework.waypoints.min.js")
// require("toastr2/dist/Toastr.umd")
require("sharer.js/sharer.js")
require("easy-autocomplete")
global.toastr = require("toastr")

global.toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": false,
  "progressBar": true,
  "positionClass": "toast-top-right",
  "preventDuplicates": true,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "2000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

// import Toastr from 'toastr';
// const toastr = new Toastr();
// toastr.options.closeMethod = 'fadeOut';
// toastr.options.closeDuration = 200;
// toastr.options.closeEasing = 'swing';
// toastr.options.positionClass = 'toast-right-left'
// toastr.options.preventDuplicates = true
// toastr.options.progressBar = true;
// toastr.options.closeButton = true;
// toastr.options.timeOut = 200
// global.toastr = toastr

// import "toastr2/dist/toastr.min.css"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import "intl-tel-input/build/css/intlTelInput.min.css"
import 'whatwg-fetch'

import Spruce from '@ryangjchandler/spruce';
Spruce.store('modals', {
  open: false,
  mobileMenu: false,
})

export default Spruce
global.Spruce = Spruce;

import visDatePicker from '../src/range_date_picker'
import ShareButtonsVis from '../src/share_buttons_vis'

import "../stylesheets/application.scss";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", function () {
  require("alpinejs");

  setupTyped()
  // dismiss any displayed modals/menus
  global.Spruce.store('modals').open = false;
  global.Spruce.store('modals').mobileMenu = false;

  // Add superscript to all applicable characters
  $('body').html(
    $('body').html().replace(/&reg;/gi, '<sup>&reg;</sup>').replace(/®/gi, '<sup>&reg;</sup>')
  );
  $('body').html(
    $('body').html().replace(/&trade;/gi, '<sup>&trade;</sup>').replace(/™/gi, '<sup>&trade;</sup>')
  );
  // Carousels
  $('.scroller').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  $('.slide-left').click(function () {
    $(this).parent().parent().find('.slick-slider').slick('slickPrev');
  })
  $('.slide-right').click(function () {
    $(this).parent().parent().find('.slick-slider').slick('slickNext');
  })

  // for elements configured with data attributes:
  $('.scroller2').slick();
  $('.scroll2-prev').click(function () {
    $(this).closest('.scroller2-container').find('.scroller2').slick('slickPrev');
  });
  $('.scroll2-next').click(function () {
    $(this).closest('.scroller2-container').find('.scroller2').slick('slickNext');
  });

  // Mentorship schedule profile
  if ($('#league-picker').length) {
    const mentorshipPicker = datepicker('#league-picker', {
      startDay: 1,
      customMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      customDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      onSelect: instance => {
        // Convert to UTC
        var date = instance.dateSelected
        var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
        var href = window.location.pathname + '?date=' + dateString;
        Turbolinks.visit(href);
      }
    })
    mentorshipPicker.show()
    const date = new URLSearchParams(window.location.search).get('date')
    if(date) {
      mentorshipPicker.setDate(new Date(`${date} 00:00`), true)
    }
  }
  // Profile
  $("#file-input-1").change(function () {
    showImage(this);
  });
  $("#file-input-1-mobile").change(function () {
    showImage(this);
  });
  $("#file-input-2").change(function () {
    showImage(this);
  });
  $("#file-input").change(function () {
    showImage(this);
  });

  // Inbox
  if ($('#conversation').length) {
    $('#conversation').scrollTop($('#conversation')[0].scrollHeight);
    $('#conversation').linkify();
  }
  $("#attachment-input").change(function () {
    showAttachment(this);
  });

  setupDropdowns();

  setupSwiper();
  visDatePicker();
  ShareButtonsVis.update();
})

$(window).bind("pageshow", function (event) {
  if (event.originalEvent.persisted) {
    window.location.reload()
  }
});

// Disable autoplaying videos on navigation

var each = Array.prototype.forEach
var autoplayIds = []

document.addEventListener("turbolinks:request-end", function () {
  // Remove autoplaying video so music doesn't persist in background
  if ($('video').length > 0) {
    $('video').remove()
  }
  if ($('bg-video').length > 0) {
    $('bg-video').remove()
  }
});

document.addEventListener('turbolinks:before-cache', function () {
  var autoplayElements = document.querySelectorAll('[autoplay]')
  each.call(autoplayElements, function (element) {
    if (!element.id) throw 'autoplay elements need an ID attribute'
    autoplayIds.push(element.id)
    element.removeAttribute('autoplay')
  })
})

document.addEventListener('turbolinks:before-render', function (event) {
  autoplayIds = autoplayIds.reduce(function (ids, id) {
    var autoplay = event.data.newBody.querySelector('#' + id)
    if (autoplay) autoplay.setAttribute('autoplay', true)
    else ids.push(id)
    return ids
  }, [])
})

// Time zone detection
import jstz from 'jstz'

function setCookie(name, value) {
  var expires = new Date()
  expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000))
  document.cookie = name + '=' + value + ';expires=' + expires.toUTCString()
}

// Rails doesn't support every timezone that Intl supports
function findTimeZone() {
  const oldIntl = window.Intl
  try {
    window.Intl = undefined
    const tz = jstz.determine().name()
    window.Intl = oldIntl
    return tz
  } catch (e) {
    // sometimes (on android) you can't override intl
    return jstz.determine().name()
  }
}

const timezone = findTimeZone()
setCookie("timezone", timezone)

// Avatar
function showImage(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function (e) {
      $(document.getElementById(input.id + '-img')).attr('src', e.target.result);
    }
    reader.readAsDataURL(input.files[0]);
  }
}

// Files
function showAttachment(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function (e) {
      $(document.getElementById(input.id + '-div')).html(input.files[0].name);
      $(document.getElementById(input.id + '-div')).addClass('visible w-32 h-8 p-1');
    }
    reader.readAsDataURL(input.files[0]);
  }
}


// Dropdowns
function setupDropdowns() {
  var x, i, j, l, ll, selElmnt, a, b, c;
  /* Look for any elements with the class "custom-select": */
  x = document.getElementsByClassName("custom-select");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /* For each element, create a new DIV that will act as the selected item: */
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /* For each element, create a new DIV that will contain the option list: */
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < ll; j++) {
      /* For each option in the original select element,
  create a new DIV that will act as an option item: */
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener("click", function (e) {
        /* When an item is clicked, update the original select box,
    and the selected item: */
        var y, i, k, s, h, sl, yl;
        var changed = false;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            changed = s.selectedIndex != i;
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
        if (changed) $(s).trigger('change');
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function (e) {
      /* When the select box is clicked, close any other select boxes,
  and open/close the current select box: */
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }

  function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
except the current select box: */
    var x, y, i, xl, yl, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }
  /* If the user clicks anywhere outside the select box,
  then close all select boxes: */
  document.addEventListener("click", closeAllSelect);
}

// Text typing animation
const setupTyped = () => {
  try {
    if (typeof Typed !== "undefined") {
      var typed = new Typed('#typed', {
        stringsElement: '#typed-strings',
        typeSpeed: 50,
        loop: true
      });

      new Waypoint({
        element: document.getElementById('typed'),
        handler: function (direction) {
          typed.reset(true);
          typed.start();
        },
        offset: '75%'
      })
    }
  } catch (error) { }

}

import Swiper, { Navigation, Pagination, Autoplay, EffectFlip, EffectFade, Mousewheel, Lazy, Scrollbar } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFlip, EffectFade, Mousewheel, Lazy, Scrollbar]) // import all used Swiper modules here

global.Swiper = Swiper

const setupSwiper = () => { // this sets up the session carousel components
  const swiper = new Swiper('.swiper.sessions', {
    slidesPerView: 'auto',
    spaceBetween: 0,
    slidesPerGroupAuto: true,
    centerInsufficientSlides: !!window.centerSwiper,
    navigation: {
      nextEl: '.swiper.sessions .swiper-custom-button-next',
      prevEl: '.swiper.sessions .swiper-custom-button-prev',
      disabledClass: 'disabled',
    },
  });
  if (window.swiperActiveIndex) {
    swiper.activeIndex = window.swiperActiveIndex
  }
  $('.before-swiper').removeClass('before-swiper')
}
